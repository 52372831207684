import { useRef, useState } from "react"
import styled from "styled-components"

import { startOfDay } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

import { ThermostatSettingsPopover } from "src/components/Homes/HomeDetails/HomeThermostat/ThermostatSettingsPopover"
import {
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { ThermostatConfiguration } from "src/components/Integrations/Integration/ThermostatConfiguration"
import {
  TThermostatSettingsAnalytics,
  usePostThermostatSettingsEditIntiated,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import {
  SortKey,
  SortOrder,
  useFetchGuests,
} from "src/data/guests/queries/guestQueries"
import { IGuest } from "src/data/guests/types/guestTypes"
import { THome } from "src/data/homes/types/homeTypes"
import {
  getCommonAvailableThermostatModes,
  hasSupportedHVACFeatures,
} from "src/data/integrations/logic/integrations"
import { useFetchIntegrationEntities } from "src/data/integrations/queries/integrationEntityQueries"
import { useFetchIntegrationHomeLinks } from "src/data/integrations/queries/integrationLinkQueries"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import {
  OwnerType,
  TIntegration,
  TIntegrationEntities,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MDivider } from "src/ui/Divider/Divider"
import AlmostFreezing from "src/ui/icons/climate-control.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function HomeThermostat({ home }: { home: THome }) {
  const { orgId } = useOrganization()

  const hvacAvailability = useFeatureAvailability({
    feature: "hvac_integrations",
  })

  const fetchIntegrations = useFetchIntegrations(
    {
      orgId,
      filters: {
        configured: true,
        integrationType: ["hvac"],
        includeAirbnbValidation: false,
        includeExternalIntegrations: false,
      },
    },
    { enabled: hvacAvailability.available }
  )

  const hvac = fetchIntegrations.data?.integrations || []
  const filteredHvac = hvac.filter((integration) =>
    hasSupportedHVACFeatures(integration)
  )

  return (
    <Box>
      {filteredHvac.map((integration) => (
        <ThermostatSettingsCard
          key={integration.integration_identifier}
          home={home}
          integration={integration}
        />
      ))}
    </Box>
  )
}

function ThermostatSettingsCard({
  home,
  integration,
}: {
  home: THome
  integration: TIntegration
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const fetchHomeLinks = useFetchIntegrationHomeLinks({
    integrationId: integration.integration_identifier,
    filters: { home_ids: [home.home_id] },
    ownerId: home.owner.id,
  })
  const homeLink = fetchHomeLinks.data?.homes?.[0]

  const postThermostatSettingsEditInitiated =
    usePostThermostatSettingsEditIntiated()

  const fetchEntities = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
  })

  const fetchGuests = useFetchGuests(home.home_id, {
    limit: 1,
    offset: 0,
    checkout_filter: {
      from: zonedTimeToUtc(startOfDay(new Date()), home.timezone),
    },
    sort_options: {
      key: SortKey.CHECK_IN,
      order: SortOrder.ASCENDING,
    },
  })

  const loading =
    fetchGuests.isLoading || fetchEntities.isLoading || fetchHomeLinks.isLoading

  const guests = fetchGuests.data?.guests || []

  const entities = fetchEntities.data?.entities || []
  const connectedEntity = entities.find(
    (e) => e.entity_id === homeLink?.entity_id
  )

  const trackIntegration: TThermostatSettingsAnalytics = {
    [integration.integration_identifier]: {
      context: "home_thermostat",
    },
  }

  if (!homeLink) {
    return null
  }

  return (
    <MonitoringCard
      titleSlots={[
        <StatusLeft
          key="left"
          title={integration.name}
          icon={<AlmostFreezing width={32} height={32} />}
          statusContents={t(langKeys.on)}
        />,
        <MButton
          key="right"
          variant="subtle"
          size="small"
          onClick={() => {
            setOpen(true)
            postThermostatSettingsEditInitiated.mutate({
              integrations: trackIntegration,
            })
          }}
          disabled={homeLink.link_status !== "linked"}
          ref={ref}
        >
          {t(langKeys.manage)}
        </MButton>,
      ]}
    >
      <ThermostatModeInfo
        homeLink={homeLink}
        connectedEntity={connectedEntity}
        loading={loading}
        timezone={home.timezone}
        guest={guests?.[0]}
      />

      {open && (
        <ThermostatSettingsPopover
          home={home}
          integrationName={integration.name}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
          homeLink={homeLink}
          connectedEntity={connectedEntity}
          trackIntegration={trackIntegration}
        />
      )}
    </MonitoringCard>
  )
}

function ThermostatModeInfo({
  homeLink,
  connectedEntity,
  loading,
  guest,
  timezone,
}: {
  homeLink: TIntegrationHomeLink
  connectedEntity?: TIntegrationEntities[number]
  loading: boolean
  guest?: IGuest
  timezone: string
}) {
  const { t, langKeys } = useTranslate()

  const isStayInUse = checkStayInUse({ guest, timezone })

  if (loading) {
    return (
      <LoadingWrapper>
        <MSkeleton variant="rect" width="100%" />
      </LoadingWrapper>
    )
  }

  return (
    <>
      <StyledDivider />

      <ModeInfo>
        <div>
          <ModeBoxContent
            title={t(langKeys.home_thermostat_last_mode_set_text)}
            connectedEntity={connectedEntity}
            homeLink={homeLink}
            isStayInUse={!isStayInUse}
            showTimeBeforeCheckin={false}
          />
        </div>

        <div>
          <ModeBoxContent
            title={t(langKeys.home_thermostat_upcoming_mode_text)}
            connectedEntity={connectedEntity}
            homeLink={homeLink}
            isStayInUse={isStayInUse}
            showTimeBeforeCheckin={true}
          />
        </div>
      </ModeInfo>
    </>
  )
}

function ModeBoxContent({
  title,
  connectedEntity,
  homeLink,
  isStayInUse,
  showTimeBeforeCheckin,
}: {
  title: string
  connectedEntity?: TIntegrationEntities[number]
  homeLink: TIntegrationHomeLink
  isStayInUse: boolean
  showTimeBeforeCheckin: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <ModeBox>
      {title}

      {isStayInUse ? (
        <div>
          <MBadge>{t(langKeys.home_thermostat_eco_mode_text)}</MBadge>
        </div>
      ) : (
        <ThermostatConfiguration
          hidden={false}
          commonAvailableModes={getCommonAvailableThermostatModes(
            connectedEntity?.devices
          )}
          linked={!!connectedEntity && homeLink.link_status !== "unlinked"}
          link={homeLink}
          showTimeBeforeCheckin={showTimeBeforeCheckin}
        />
      )}
    </ModeBox>
  )
}

function checkStayInUse({
  guest,
  timezone,
}: {
  guest?: IGuest
  timezone: string
}): boolean {
  if (!guest) {
    return false
  }

  const nowUtc = zonedTimeToUtc(Date.now(), timezone).getTime()

  const checkinTimeIsPast =
    !!guest.checkin_time && new Date(guest.checkin_time).getTime() < nowUtc

  const checkoutTimeIsPast =
    !!guest.checkout_time && new Date(guest.checkout_time).getTime() < nowUtc

  return checkinTimeIsPast && !checkoutTimeIsPast
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const StyledDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const ModeInfo = styled.div`
  display: flex;
  gap: ${spacing.XL2};
`
const ModeBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
`

const LoadingWrapper = styled.div`
  margin: ${spacing.XS} 0;
`
